//scss color map
$colours: (
        "orange": #E28126,
);

//button styles
.button {
  background: map-get($colours, "orange");
  color: #fff;
  font: bold 16px montserrat, sans-serif;
  text-transform: uppercase;
  border-radius: 50px;
  display: inline-block;
  padding: 16px 20px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  text-decoration: none;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.orange-note {
  position: absolute;
  bottom: 0;
  right: 100px;
  background: transparent linear-gradient(144deg, #E28126 0%, #EB9F1A 100%) 0% 0% no-repeat padding-box;
  padding: 38px 31px;

  .close-icon {
    width: 17px;
    height: 17px;
    background: url("../assets/images/close-icon.svg") no-repeat;
    background-size: 100%;
    position: absolute;
    right: 22px;
    top: 26px;
    cursor: pointer;
  }

  h1 {
    font: bold 29px/29px "Roboto", sans-serif;
    color: #fff;
    margin-bottom: 15px;
  }

  p {
    font: 500 19px/27px 'Roboto', sans-serif;
    color: #fff;
  }
}
