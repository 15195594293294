.view.home {
  width: 1088px;
  margin: 0 auto;

  h1 {
    font: bold 29px/38px 'Roboto', sans-serif;
    color: #fff;
    margin-bottom: 25px;
  }

  h2 {
    font: bold 20px/28px 'Roboto', sans-serif;
    margin-bottom: 33px;
  }

  .split-box {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #fff;
    width: 650px;
    padding-bottom: 26px;
    margin-bottom: 34px;

    .sma-logos {
      background: url("../assets/images/sma-logos.svg") no-repeat;
      background-size: 100%;
      height: 86px;
      width: 200px;
    }

    p {
      font: bold 20px/28px 'Roboto', sans-serif;
      color: #fff;
      margin-left: 20px;
    }
  }

  h3 {
    font: 500 18px/27px 'Roboto', sans-serif;
    color: #fff;
    width: 650px;
  }

  .rock-on-container {
    margin-top: 70px;
    width: 488px;

    h1 {
      margin-bottom: 10px;
    }

    input {
      appearance: none;
      border: 0;
      background: #fff;
      color: #0C3938;
      font: 400 16px/19px montserrat, sans-serif;
      text-transform: uppercase;
      border-radius: 50px;
      height: 50px;
      outline: none;
      text-align: center;
      margin-top: 30px;
      width: 100%;

      &::placeholder {
        font: 400 16px/19px montserrat, sans-serif;
        color: #0C3938;
      }
    }

    .button {
      display: block;
      margin-top: 23px;
    }
  }

  p.footnote {
		max-width: 80%;
    margin-top: 47px;
    font: 400 16px/1.1 'Roboto', sans-serif;
    color: #fff;
    opacity: 0.7;
  }

  .orange-note {
    width: 376px;
    height: 416px;
  }
}
