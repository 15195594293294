.view.result {
  width: 1088px;
  margin: 0 auto;

  h1 {
    font: bold 53px/60px 'Roboto', sans-serif;
    color: #fff;
    text-transform: uppercase;
  }

  .score-label {
    font: 500 18px/18px 'Roboto', sans-serif;
    color: #fff;
    text-transform: uppercase;
    margin: 40px 0 10px;
  }

  .score-split {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    .score {
      font: bold 60px/60px 'Roboto', sans-serif;
    }

    .restart {
      margin-left: 50px;
      width: 218px;
    }
  }

  .split-box {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #fff;
    width: 768px;
    padding-bottom: 26px;
    margin-bottom: 34px;

    .sma-logos {
      background: url("../assets/images/sma-logos.svg") no-repeat;
      background-size: 100%;
      height: 86px;
      width: 200px;
    }

    p {
      font: bold 20px/28px 'Roboto', sans-serif;
      color: #fff;
      margin-left: 20px;
    }
  }

  p.sma-month {
    font: 400 19px/27px 'Roboto', sans-serif;
    color: #fff;
    width: 781px;
    margin-bottom: 66px;
  }

  h3 {
    font: bold 29px/29px 'Roboto', sans-serif;
    color: #fff;
  }

  .cta-buttons {
    margin-top: 20px;

    .button {
      width: 235px;
      margin-right: 20px;
    }
  }

  .footnote {
		max-width: calc(70% - 400px);
    font: 400 16px/1.1 'Roboto', sans-serif;
    color: #fff;
    position: absolute;
    bottom: 47px;
    opacity: 0.68;
  }

  .orange-note {
    width: 376px;
    height: 226px;
  }
}
