//Montserrat font
@import url("https://use.typekit.net/tcs0ron.css");
//Roboto font
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
//colours
@import "shared";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

sup {
  font-size: 60%;
  line-height: 0;
}

.container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform-origin: center center;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  width: 1920px;
  height: 1302px;
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .video-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0;

    &.active {
      opacity: 1;
    }
  }

  header {
    width: 1088px;
    margin: 53px auto;

    .logo {
      background: url("../assets/images/RockSmarter_logo.svg") no-repeat;
      background-size: 100%;
      height: 182px;
      width: 620px;
      margin-left: -86px;
    }
  }

  main {
    position: relative;
    flex: 1;
  }

  footer {
    background: #000;
    color: #ADADAD;

    .content {
      padding: 40px 0 37px;
      width: 1088px;
      margin: 0 auto;

      .scholar-rock-logo {
        background: url("../assets/images/scholar-rock-logo.svg") no-repeat;
        background-size: 100%;
        width: 345px;
        height: 60px;
      }

      .copyright {
        display: flex;
        justify-content: space-between;
        font: 500 14px montserrat, sans-serif;
        margin-top: 20px;
        text-align: center;
      }
    }
  }
}
