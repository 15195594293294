@import "shared";

.view.questions {
  .card-container {
    width: 1088px;
    margin: 0 auto;

    .question-count {
      font: 400 26px montserrat, sans-serif;
      color: #fff;

      span {
        font-size: 32px;
        font-weight: bold;
      }
    }

    .question-card {
      position: relative;
      border: 1px solid #fff;
      display: inline-block;
      margin: 110px 0 70px;
			font: bold 26px/1.2 'Roboto', sans-serif;
			color: #fff;
			padding: 50px 50px 50px 40px;
			max-width: 750px;

      &:before, &:after {
        content: '';
        position: absolute;
        background: url("../assets/images/speech-marks.png") no-repeat;
        background-size: 100%;
        pointer-events: none;
      }

      &:before {
        width: 95px;
        height: 77px;
        top: -55px;
        left: -60px;
        transform: rotate(180deg);
      }

      &:after {
        width: 119px;
        height: 96px;
        bottom: -70px;
        right: -75px;
      }

      p {
        font: bold 34px/42px 'Roboto', sans-serif;
        color: #fff;
        padding: 50px 50px 50px 40px;
      }
			ol {
				margin: 15px 0 0 40px;
			}
    }

    .result-card {
      margin-bottom: 46px;

      &.correct .content {
        background: rgba(42, 72, 45, 0.57);
      }

      &.wrong .content {
        background: rgba(0, 54, 108, 0.57);
      }

      &.block .content {
        margin-left: -200px;
        display: flex;
        margin-right: -210px;
        justify-content: space-between;
      }

      &.quote .content, &.fact .content {
        display: block;
      }

      &.block .content {
        display: flex;
      }

      h1 {
        font: bold 58px/58px 'Roboto', sans-serif;
        color: #fff;
        padding: 27px 0 10px;
      }

      .content {
        position: relative;
        border: 2px solid #fff;
        margin-top: 50px;
        display: inline-block;
				max-width: 780px;

        &.long {
          width: 1220px;
        }

        p {
          font: 500 26px/36px 'Roboto', sans-serif;
          color: #fff;
          padding: 20px;

          em {
            font-weight: normal;
            font-style: italic;
          }

          > p {
            padding: 7px 10px;
          }
        }

        .quote {
          position: absolute;
          top: 0;
          right: 0;
          background: url("../assets/images/quote-blob.svg") no-repeat;
          background-size: 100%;
          transform: translate(35%, -8%);

          &.large {
            transform: translate(15%, -23%);
          }

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .quote-text {
            position: relative;
            font: 500 23px/32px 'Roboto', sans-serif;
            color: #fff;
            margin: 0 20px 20px;
            padding: 0;
            display: inline-block;
            text-align: center;
            max-width: 303px;

            &:before, &:after {
              content: '';
              background: url("../assets/images/speech-marks.png") no-repeat;
              background-size: 100%;
              width: 20px;
              height: 16px;
              display: block;
              margin: 0 auto;
            }

            &:before {
              transform: rotateZ(180deg);
              margin-bottom: 18px;
            }

            &:after {
              margin-top: 18px;
            }
          }

          .author {
            font: 300 19px/22px 'Roboto', sans-serif;
            color: #fff;
            margin: 0;
            padding: 0;
            text-align: center;
          }

          &.fact {
            p {
              text-align: center;
              text-transform: uppercase;
              font: 500 22px/28px montserrat, sans-serif;

              b {
                font: bold 32px/36px montserrat, sans-serif;
              }
            }
          }
        }

        .text-block {
          background: #000;
          width: 599px;
          padding: 0 18px;
        }
      }
    }

    .answer-buttons {
      opacity: 0;

      .button {
        min-width: 145px;
        margin-right: 30px;

        &.next {
          min-width: 142px;
          min-width: auto;
        }
      }
    }

    .fade-enter {
      opacity: 0;
    }

    .fade-exit {
      opacity: 1;
    }

    .fade-enter-active {
      opacity: 1;
    }

    .fade-exit-active {
      opacity: 1;
    }

    .fade-enter-active {
      transition: opacity 500ms 2s;
    }

    .fade-enter-done {
      opacity: 1;
    }

    .references {
      position: absolute;
      bottom: 30px;

      .title {
        font: 500 13px/16px montserrat, sans-serif;
        color: #fff;
      }

      ol {
        margin-left: 14px;

        li {
          color: #fff;
          font: 400 12px/16px montserrat, sans-serif;

          a {
            color: map-get($colours, 'orange');
          }
        }
      }
    }
  }
}
